<template>
  <div v-if="Object.keys(conversationActive || {}).length>0" class="justify-content-start h-100 view-container">
    <HeaderResidential />
    <div class="mt-4">
        <div>
          <div class="title">
            <a @click="close"  class="go-back-icon">
              <i class="left chevron icon general-blue-color"></i>
            </a>
            <h3>{{ truncateText(conversationActive.subject, maxMsgSubjectLengthEditConversation) }}</h3>
          </div>
          <div class="container_add_remove_members_buttons_and_search_bar_members">
            <div class="add_remove_members_buttons">
              <Button
              v-if="conversationActive.canAddMembers"
                :text="$t('messages.addMembers')"
                color="primary"
                class="d-inline"
                @clicked="addMembers"
              />
              <Button
              v-if="conversationActive.canAddMembers"
                :class="{'disabled': !isSomeResidentialMemberSelectedToRemove}"
                color="third-color"
                :text="$t('messages.deleteMembers')"
                class="d-inline ml-1 remove_members_button"
                :appearance="{
                  'padding': '7px 2.4rem !important',
                  'margin-right': '0 !important'
                }"
                @clicked="removeResidentialMembers"
              />
            </div>
              <div class="ui icon input search_bar_members">
                <input type="text" v-model="searchTerm" :placeholder="$t('search')">
                <i class="search icon"></i>
              </div>
          </div>
        </div>
        <div class="col-md-3"></div>
      <div class="mt-3 mb-5">
          <table class="ui blue unstackable selectable celled table members_list">
            <thead>
            <tr>
              <th>{{$t('messages.member')}}</th>
              <th style="width: 7%" class="text-center">{{$t('messages.message')}}</th>
              <th style="width: 10%" class="text-center">{{$t('building.apartment')}}</th>
            </tr>
            </thead>
            <tbody v-if="conversationMembers.length === 0">
              <tr>
                <td colspan="8" class="text-center">
                  {{$t('messages.noResidentsFound')}}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr
                v-for="(member, idx) in conversationMembers" :key="idx"
              >
              <!--  -->
                <td style="display: flex; flex-direction: row; align-items: center;">
                  <div class="left_space_member" v-if="conversationActive.canAddMembers">
                    <div v-if="conversationOwner(member)" class="ui checkbox">
                      <input type="checkbox" name="example" v-model="residentialMembersToDelete[idx]">
                      <label></label>
                    </div>
                  </div>
                  <!-- member_name_and_avatar -->
                  <div class="member_name_and_avatar">
                    <img
                      :src="getAvatar(member.avatar)"
                      class="ui mini rounded image member_avatar_img"
                    >
                    <span class="member_name">{{ `${member.firstName} ${member.lastName}` }}</span>
                  </div>
                  <!-- <h4 class="ui image header">
                    <img
                      :src="getAvatar(member.avatar)"
                      class="ui mini rounded image member_avatar_img"
                    >
                    <div class="content">{{ `${member.firstName} ${member.lastName}` }}</div>
                  </h4> -->
                  <!-- <div class="member_basic_info">
                    <div class="left_space_member">
                      <div v-if="member.id !== adminUser.id" class="ui checkbox">
                        <input type="checkbox" name="example" v-model="residentialMembersToDelete[idx]">
                        <label></label>
                      </div>
                    </div>
                      <h4 class="ui image header member_name_and_avatar">
                        <img
                          :src="getAvatar(member.avatar)"
                          class="ui mini rounded image member_avatar_img"
                        >
                        <div class="content">{{ `${member.firstName} ${member.lastName}` }}</div>
                      </h4>
                  </div> -->
                </td>
                <td class="text-center">
                  <router-link v-if="member.id !== adminUser.id && !(member.id in residentialAdminsDict)" :to="{
                    path: '/messaging',
                    }"
                    :data-tooltip="$t('messages.sendMessage')"
                    data-position="bottom center"
                    style="color: var(--messaging-color)"
                    @click.prevent="sendMessage(member)"
                  >
                    <i class="envelope icon"></i>
                  </router-link>
                </td>
                <td @click="editResident(member)" class="text-center">{{ member.apartment }}</td>
              </tr>
            </tbody>
          </table>
      </div>
    </div>
  </div>
  <EditConversationModal v-if="Object.keys(conversationActive || {}).length>0"
      :show="isEditingConversationModal"
      :editConversation="conversationActive"
      @close="closeModal"
    />
</template>

<script>
import HeaderResidential from '@/components/ui/HeaderResidential'
import Button from '@/components/form/Button'
import EditConversationModal from '@/components/modals/EditConversationModal.vue'
import {
  mapActions,
  mapGetters
} from 'vuex'
import helpers from '@/utils/helpers'
import { RolesEnum } from '@/utils/roles.enum.js'

export default {
  name: 'EditConversation',
  components: {
    HeaderResidential,
    Button,
    EditConversationModal
  },
  data () {
    return {
      isAddingMembers: false,
      conversationMembersIds: [],
      residentialMembersToDelete: [],
      maxMsgSubjectLengthEditConversation: 150,
      searchTerm: ''
    }
  },
  created () {
    this.$nextTick(() => {
      if (!this.conversationActive) {
        this.$router.replace({ path: '/messaging' })
      }
    })
  },
  mixins: [{
    methods: {
      truncateText: helpers.truncateText,
      getAvatar: helpers.getAvatar
    }
  }],
  computed: {
    residentialMembersDictionary () { // <- podría ser ideal que se trabaje residents + admin + ... con este objeto
      return this.$store.getters['user/residentialMembersDictionary']
    },
    residentialAdminsDict () {
      const resMemDict = this.residentialMembersDictionary[RolesEnum.ADMINISTRATOR]
      // return this.residentialMembersDictionary[RolesEnum.ADMINISTRATOR]
      // console.log('computed residentialAdminsDict ----', resMemDict)
      return resMemDict
    },

    ...mapGetters('messaging', ['conversationActive', 'conversationInfo']),
    ...mapGetters('routes', ['fromRoute', 'toRoute']),

    isEditingConversationModal () {
      return this.isAddingMembers
    },
    residentsDict () {
      return this.$store.getters['user/residentsDictionary']
    },
    adminUser () {
      return this.$store.getters['user/user']
    },
    conversationMembers () {
      // console.log('conversationMembers ----')
      return this.conversationMembersIds
        .map(userId => this.formatUserToShow(userId))
        .filter(member => {
          const fullName = `${member.firstName} ${member.lastName}`
          const searchString = `${fullName} ${member.apartment}`.toLowerCase()
          return this.normalizeText(searchString.trim()).includes(this.searchTerm.toLowerCase())
        })
        .sort((a, b) => {
          if (a.apartment === 'ADMIN') return -1
          if (b.apartment === 'ADMIN') return 1

          // Separate letters and numbers
          const regex = /^([a-z]*)(\d*)$/i
          const matchA = a.apartment.match(regex)
          const matchB = b.apartment.match(regex)
          if (!matchA || matchA.length === 0) return -1
          if (!matchB || matchB.length === 0) return 1
          // Compare letters first
          if (matchA[1] < matchB[1]) return -1
          if (matchA[1] > matchB[1]) return 1

          // If letters are equal, compare numbers
          // Here, we're considering that if there's no number part, it should come last
          const numA = matchA[2] === '' ? Infinity : parseInt(matchA[2], 10)
          const numB = matchB[2] === '' ? Infinity : parseInt(matchB[2], 10)

          return numA - numB
        })
    },
    isSomeResidentialMemberSelectedToRemove () {
      return this.residentialMembersToDelete.some(x => x)
    }
  },
  mounted () {
    if (!this.conversationActive) {
      this.$router.replace({ path: '/messaging' })
    } else {
      this.conversationMembersIds = []
      this.conversationMembersIds = this.conversationActive.participants
        .map(idString => parseInt(idString))
        .concat([+this.conversationActive.user._id])
      this.residentialMembersToDelete = new Array(this.conversationMembersIds.length).fill(false)
    }
  },
  beforeUnmount () {
    if (!['/messaging', '/messaging/view'].includes(this.toRoute)) {
      // console.log('going out of messaging routes')
      this.$store.dispatch('messaging/setResidentialMembersToMessage', [])
      this.$store.dispatch('messaging/setConversationActive', null)
    }
  },
  methods: {
    ...mapActions('loading', ['setLoading']),
    closeModal () {
      this.isAddingMembers = false
      this.modalData = {}
    },
    normalizeText (input) {
      return input.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    },
    addMembers () {
      this.isAddingMembers = true
    },
    conversationOwner (member) {
      return (member.id !== this.adminUser.id && member.id.toString() !== this.conversationActive.user._id.toString())
    },
    close () {
      this.$router.back()
    },
    formatUserToShow (userId) {
      let user
      let apartment
      // console.log('incoming userId ---->', userId)
      if (userId in this.residentialAdminsDict) {
        user = this.residentialAdminsDict[userId]
        apartment = 'ADMIN'
      } else {
        user = this.residentsDict[userId]
        apartment = user.apartment
      }
      // console.log('user info is ---->', user)
      const {
        // id,
        firstName,
        lastName,
        avatar,
        building
      } = user
      // if (email === this.adminUser.email) { // if admin is also a resident
      //   user = this.adminUser
      // }
      const fullName = `${firstName} ${lastName}`
      const specs = `${userId.building} - ${userId.apartment}`
      return {
        // id: this.residentsDict[userId] ? userId : id,
        id: userId,
        firstName: firstName,
        lastName: lastName,
        description: `${fullName}, ${specs}`,
        building: building,
        avatar: avatar,
        apartment: apartment
      }
    },
    sendMessage (member) {
      // console.log('member info is ---->', member)
      this.$store.dispatch('messaging/setResidentialMembersToMessage', [member])
    },
    removeResidentialMembers () {
      this.$swal({
        title: this.$t('messages.sureToDeleteMembers'),
        showCancelButton: true,
        confirmButtonText: this.$t('yes'),
        confirmButtonColor: '#dc3545',
        cancelButtonText: this.$t('no'),
        cancelButtonColor: '#2186b0',
        customClass: {
          popup: 'remove-residential-conversationMembers-popup-confirmation'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.setLoading(true)
          const residentialMembersToDeleteWithInfo = this.conversationMembers.filter((_, idx) => this.residentialMembersToDelete[idx]).map(member => {
            // const resident = this.residentsDict[member.id]
            let user
            let specs
            // console.log('incoming member.id ---->', member.id)
            if (member.id in this.residentialAdminsDict) {
              user = this.residentialAdminsDict[member.id]
              specs = 'ADMIN'
            } else {
              user = this.residentsDict[member.id]
              specs = `${user.building} - ${user.apartment}`
            }
            return {
              id: member.id,
              firstName: user.firstName,
              lastName: user.lastName,
              avatar: user.avatar,
              specs: specs
            }
          })
          this.$store.dispatch('messaging/editConversationMembers', {
            conversationId: this.conversationActive.uid,
            data: {
              membersToRemove: residentialMembersToDeleteWithInfo
            }
          })
          this.conversationMembersIds = this.conversationActive.participants
            .map(idString => parseInt(idString))
            .concat([+this.conversationActive.user._id])
          this.residentialMembersToDelete = new Array(this.conversationMembersIds.length).fill(false)
          this.setLoading(false)
          this.$swal(this.$t('messages.membersDeletedSuccess'), '', 'success')
          this.close()
        }
      })
    }
  },
  watch: {
    conversationActive (value) {
      if (!value) {
        this.$router.replace({ path: '/messaging' })
      }
    }
  }
}
</script>

<style scoped>
  .ui.image.header > .content {
    font-size: 14px;
    font-weight: normal;
  }

  .title {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    padding-bottom: 10px;;
  }

  .go-back-icon {
    text-decoration: none;
    cursor: pointer;
    font-size: 20px;
  }
  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  .container_add_remove_members_buttons_and_search_bar_members {
    display: flex; flex-direction: row; align-items: center; justify-content: space-between;
  }

  .members_list {
    color: #808080;
    padding: 0 .5em;
  }

  .member_basic_info{
    display: flex; flex-direction: row; align-items: center;
  }

  .left_space_member {
    width: 35px;
  }
  .member_avatar_img {
    height: 35px !important;
    width: 35px !important;
    border-radius: 17px !important;
    object-fit: cover !important;
  }

  .member_name_and_avatar {
    /* width: 80%; */
    display: flex;
    /* flex-direction: row; */
    align-items: center;
  }
  .member_name {
      /* text-align: center; */
      margin-left: 10px;
    }

  @media only screen and (max-width: 400px) {
    .member_name {
      text-align: center;
      margin-left: 0;
    }
    .member_name_and_avatar{
      flex-direction: column;
    }
  }

  /* Styles for screens smaller than 768px (e.g., mobile devices) */
  @media only screen and (max-width: 670px) {
    .container_add_remove_members_buttons_and_search_bar_members {
      flex-direction: column;
    }
    .search_bar_members, .add_remove_members_buttons {
      width: 100%;
    }
    .add_remove_members_buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      /* jus */
    }
    .search_bar_members
    {
      margin-top: 5px;
    }
  }
</style>
